<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      크게 보기 단일 평가
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="gray-box d-flex">
        <div class="">
          <div>
            <v-text-field
              class="form-inp "
              name="name" label="평가명" id="id" outlined hide-details=""
              v-model="gridSelected.QA_NM"
              readonly>
            </v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="평가대상구분" id="id" outlined hide-details=""
              v-model="gridSelected.QA_TA_DV_NM"
              readonly>
            </v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="평가유형" id="id" outlined hide-details=""
              v-model="gridSelected.QA_TY_NM"
              readonly>
            </v-text-field>
            <v-text-field
                v-model="dateRangeText"
                readonly
                outlined
                hide-details
                class="form-inp ml-2 icon-calendar"
                append-icon="svg-calendar"
                label="평가기간"
            ></v-text-field>

            <v-text-field
              class="form-inp ml-2"
              name="name" label="평가표명" id="id" outlined hide-details=""
              v-model="gridSelected.QA_EVA_NM" readonly>
            </v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="평가상태" id="id" outlined hide-details="" v-model="getQaSt" readonly>
            </v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="상담사명" id="id" outlined hide-details="" v-model="gridSelected.QA_DATA_US_NM" readonly>
            </v-text-field>
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <div v-if="this.mixin_set_btn(this.$options.name, 'btnHear')">
            <v-btn outlined class="btn-etc2" v-if="gridSelected.QA_TG_TY == '10'" @click="$emit('getAudio')">녹취청취</v-btn>
          </div>
          <div v-if="this.mixin_set_btn(this.$options.name, 'btnChat')">
            <v-btn outlined class="btn-default ml-2" v-if="gridSelected.QA_TG_TY == '20'" @click="$emit('getChat')">채팅보기</v-btn>
          </div>
        </div>
      </div>
      <!-- 평가표 -->
      <div class="mt-3">
        <h2 class="tit-h2 d-flex">평가표
          <div class="ml-auto align-self-center">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point" @click="$emit('submit')">저장</v-btn>
          </div>
        </h2>
        <div class="box-ct mt-3">
          <div class="table-form">
              <table>
                <caption class="hide">
                  항목
                </caption>
                <colgroup>
                  <col width="120px" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col" class="py-2">목록</th>
                    <th scope="col">배점</th>
                    <th scope="col">문항</th>
                    <th scope="col">1차 평가
                      <div v-if="this.mixin_set_btn(this.$options.name, 'btnAllCol1')">
                      <v-btn outlined small class="btn-default"
                      @click="setPerfect('1')"
                      v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN == 'N' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'"
                      >만점</v-btn>
                      </div>
                    </th>
                    <th scope="col">1차 코멘트</th>
                    <th scope="col">1차 이의제기</th>
                    <th scope="col">2차 평가
                      <div v-if="this.mixin_set_btn(this.$options.name, 'btnAllCol2')">
                      <v-btn outlined small class="btn-default"
                      @click="setPerfect('2')"
                      v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN == 'N' && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99' && gridSelected.QA_EVA_CN >=2"
                      >만점</v-btn>
                      </div>
                    </th>
                    <th scope="col">2차 코멘트</th>
                    <th scope="col">2차 이의제기</th>
                    <th scope="col">3차 평가
                      <div v-if="this.mixin_set_btn(this.$options.name, 'btnAllCol3')">
                      <v-btn outlined small class="btn-default"
                      @click="setPerfect('3')"
                      v-if="QA_MANAGE_YN == 'Y' && gridSelected.FN_RST_SAVE_YN == 'N' && (gridSelected.QA_TA_ST == '35' || gridSelected.QA_TA_ST == '40') && gridSelected.QA_TA_ST != '99'  && gridSelected.QA_EVA_CN ==3"
                      >만점</v-btn>
                      </div>
                    </th>
                    <th scope="col">종합의견</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center"> 총점수 </td>
                    <td class="text-center">{{gridSelected.QA_EVA_SUM}}</td>
                    <td>
                    </td>
                    <td class="text-center">{{getCn1Sc}}</td>
                    <td class="text-center">
                    </td>
                    <td class="text-center">
                    </td>
                    <td class="text-center">{{getCn2Sc}}</td>
                    <td class="text-center">
                    </td>
                    <td class="text-center">
                    </td>
                    <td class="text-center">{{getCn3Sc}}</td>
                    <td>
                    </td>
                  </tr>
                    <template v-for="(item, index) in qaQsList">
                      <tr :key="index">
                        <template>
                          <!-- <v-text-field v-show="false" v-model="dataRstList[index].QA_QS_VE_ID" :value="item.QA_QS_VE_ID"></v-text-field> -->
                        </template>
                        <template v-if="index == 0 || item.QA_QS_ID != qaQsList[index - 1].QA_QS_ID">
                          <td class="text-center" :rowspan="item.VE_COUNT">{{item.QA_QS_INDEX}}. {{item.QA_QS_NM}}({{item.QA_QS_TY_SC}}점)</td>
                        </template>
                        <td class="text-center">{{item.QA_QS_VE_SC}}</td>
                        <td>
                          {{item.QA_QS_VE_RT}}
                        </td>
                        <td class="text-center">
                          <!-- 객관식 -->
                          <template v-if="item.QA_QS_TY == '10'">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'">
                              <input type="radio"
                              class="radio_rst"
                              :id="'pop_radio_rst1_' + item.QA_QS_VE_ID"
                              :name="'pop_radio_rst1_' + item.QA_QS_ID"
                              :value="item.QA_QS_VE_ID"
                              :checked="item.QA_QS_VE_ID_1 == item.QA_QS_VE_ID? true: false"
                              @change="setSc('1')"
                              />
                            </template>
                            <template v-else>
                              <v-icon small v-if="item.QA_QS_VE_ID_1 != null && item.QA_QS_VE_ID_1 != ''">mdi-check-circle</v-icon>
                            </template>
                          </template>
                          <!-- 서술형-->
                          <template v-else-if="item.QA_QS_TY == '20'">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'">
                              <v-text-field
                              class="form-inp sm ml-2"
                                name="name" id="id" outlined hide-details=""
                                type="number"
                                min="0"
                                :max="item.QA_QS_VE_SC"
                                v-model="item.QA_DATA_RST_SUM_1"
                                @change="setSc('1')"
                              ></v-text-field>
                            </template>
                            <template v-else>
                              {{item.QA_DATA_RST_SUM_1}}
                            </template>
                          </template>
                          <!-- 체크박스 -->
                          <template v-else-if="item.QA_QS_TY == '30'">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'">
                                <v-checkbox
                                  class="d-inline-flex"
                                  :on-icon="'svg-chk-on'"
                                  :off-icon="'svg-chk-off'"
                                  hide-details
                                  :id="'pop_qaVe_'+index"
                                  :name="'pop_qaVe_' + item.QA_QS_ID"
                                  v-model="item.QA_QS_VE_ID_1"
                                  :true-value="item.QA_QS_VE_ID"
                                  false-value=""
                                  @change="setSc('1')"
                                  >
                                </v-checkbox>
                            </template>
                            <template v-else>
                              <v-icon small v-if="item.QA_QS_VE_ID_1 != null && item.QA_QS_VE_ID_1 != ''">mdi-check-circle</v-icon>
                            </template>
                          </template>
                        </td>
                        <td class="text-center">
                          <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'">
                            <v-text-field
                              class="form-inp sm ml-2"
                                name="name" id="id" outlined hide-details=""
                                v-model="item.QA_DATA_RST_COM_1"
                                v-if="item.QA_QS_VE_ID_1 != null && item.QA_QS_VE_ID_1 != ''? true: false"
                                max="500"
                              ></v-text-field>
                          </template>
                          <template v-else>
                            {{item.QA_DATA_RST_COM_1}}
                          </template>
                        </td>
                        <td class="text-center">
                          <template v-if="item.QA_TA_ST == '25' && QA_MANAGE_YN == 'N' && gridSelected.FS_RST_SAVE_YN == 'Y' && item.QA_DATA_RST_COM_RE_SAVE_YN_1 == 'N' && gridSelected.QA_TA_ST != '99'">
                            <v-text-field
                              class="form-inp sm ml-2"
                                name="name" id="id" outlined hide-details=""
                                v-model="item.QA_DATA_RST_COM_RE_1"
                                v-if="item.QA_QS_VE_ID_1 != null && item.QA_QS_VE_ID_1 != ''? true: false"
                                max="500"
                              ></v-text-field>
                          </template>
                          <template v-else>
                            {{item.QA_DATA_RST_COM_RE_1}}
                          </template>
                        </td>
                        <!-- 2차 평가 -->
                        <td class="text-center">
                          <template v-if="gridSelected.QA_EVA_CN >= 2">
                            <!-- 객관식 -->
                            <template v-if="item.QA_QS_TY == '10'">
                              <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99'">
                                <input type="radio"
                                class="radio_rst"
                                :id="'pop_radio_rst2_' + item.QA_QS_VE_ID"
                                :name="'pop_radio_rst2_' + item.QA_QS_ID"
                                :value="item.QA_QS_VE_ID"
                                :checked="item.QA_QS_VE_ID_2 == item.QA_QS_VE_ID? true: false"
                                @change="setSc('2')"
                                />
                              </template>
                              <template v-else>
                                <v-icon small v-if="item.QA_QS_VE_ID_2 != null && item.QA_QS_VE_ID_2 != ''">mdi-check-circle</v-icon>
                              </template>
                            </template>
                            <!-- 서술형-->
                            <template v-else-if="item.QA_QS_TY == '20'">
                              <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN != 'Y'  && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99'">
                                <v-text-field
                                class="form-inp sm ml-2"
                                  name="name" id="id" outlined hide-details=""
                                  type="number"
                                  min="0"
                                  :max="item.QA_QS_VE_SC"
                                  v-model="item.QA_DATA_RST_SUM_2"
                                  @change="setSc('2')"
                                ></v-text-field>
                              </template>
                              <template v-else>
                                {{item.QA_DATA_RST_SUM_2}}
                              </template>
                            </template>
                            <!-- 체크박스 -->
                            <template v-else-if="item.QA_QS_TY == '30'">
                              <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99'">
                                  <v-checkbox
                                    class="d-inline-flex"
                                    :on-icon="'svg-chk-on'"
                                    :off-icon="'svg-chk-off'"
                                    hide-details
                                    :id="'pop_qaVe_'+index"
                                    :name="'pop_qaVe_' + item.QA_QS_ID"
                                    v-model="item.QA_QS_VE_ID_2"
                                    :true-value="item.QA_QS_VE_ID"
                                    false-value=""
                                    @change="setSc('2')"
                                    >
                                  </v-checkbox>
                              </template>
                              <template v-else>
                                <v-icon small v-if="item.QA_QS_VE_ID_2 != null && item.QA_QS_VE_ID_2 != ''">mdi-check-circle</v-icon>
                              </template>
                            </template>
                          </template>
                        </td>
                        <td class="text-center">
                          <template v-if="gridSelected.QA_EVA_CN >= 2">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99'">
                              <v-text-field
                                class="form-inp sm ml-2"
                                  name="name" id="id" outlined hide-details=""
                                  v-model="item.QA_DATA_RST_COM_2"
                                  v-if="item.QA_QS_VE_ID_2 != null && item.QA_QS_VE_ID_2 != ''? true: false"
                                  max="500"
                                ></v-text-field>
                            </template>
                            <template v-else>
                              {{item.QA_DATA_RST_COM_2}}
                            </template>
                          </template>
                        </td>
                        <td class="text-center">
                          <template v-if="gridSelected.QA_EVA_CN >= 2">
                            <template v-if="item.QA_TA_ST == '35' && QA_MANAGE_YN == 'N' && gridSelected.SC_RST_SAVE_YN == 'Y' && item.QA_DATA_RST_COM_RE_SAVE_YN_2 == 'N' && gridSelected.QA_TA_ST != '99'">
                              <v-text-field
                                class="form-inp sm ml-2"
                                  name="name" id="id" outlined hide-details=""
                                  v-model="item.QA_DATA_RST_COM_RE_2"
                                  v-if="item.QA_QS_VE_ID_2 != null && item.QA_QS_VE_ID_2 != ''? true: false"
                                  max="500"
                                ></v-text-field>
                            </template>
                            <template v-else>
                              {{item.QA_DATA_RST_COM_RE_2}}
                            </template>
                          </template>
                        </td>
                        <!-- 3차 평가 -->
                        <td class="text-center">
                          <template v-if="gridSelected.QA_EVA_CN == 3">
                            <!-- 객관식 -->
                            <template v-if="item.QA_QS_TY == '10'">
                              <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FN_RST_SAVE_YN != 'Y'  && (gridSelected.QA_TA_ST == '35' || gridSelected.QA_TA_ST == '40') && gridSelected.QA_TA_ST != '99'">
                                <input type="radio"
                                class="radio_rst"
                                :id="'pop_radio_rst3_' + item.QA_QS_VE_ID"
                                :name="'pop_radio_rst3_' + item.QA_QS_ID"
                                :value="item.QA_QS_VE_ID"
                                :checked="item.QA_QS_VE_ID_3 == item.QA_QS_VE_ID? true: false"
                                @change="setSc('2')"
                                />
                              </template>
                              <template v-else>
                                <v-icon small v-if="item.QA_QS_VE_ID_3 != null && item.QA_QS_VE_ID_3 != ''">mdi-check-circle</v-icon>
                              </template>
                            </template>
                            <!-- 서술형-->
                            <template v-else-if="item.QA_QS_TY == '20'">
                              <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FN_RST_SAVE_YN != 'Y'  && (gridSelected.QA_TA_ST == '35' || gridSelected.QA_TA_ST == '40') && gridSelected.QA_TA_ST != '99'">
                                <v-text-field
                                class="form-inp sm ml-2"
                                  name="name" id="id" outlined hide-details=""
                                  type="number"
                                  min="0"
                                  :max="item.QA_QS_VE_SC"
                                  v-model="item.QA_DATA_RST_SUM_3"
                                  @change="setSc('2')"
                                ></v-text-field>
                              </template>
                              <template v-else>
                                {{item.QA_DATA_RST_SUM_3}}
                              </template>
                            </template>
                            <!-- 체크박스 -->
                            <template v-else-if="item.QA_QS_TY == '30'">
                              <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FN_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '35' || gridSelected.QA_TA_ST == '40') && gridSelected.QA_TA_ST != '99'">
                                  <v-checkbox
                                    class="d-inline-flex"
                                    :on-icon="'svg-chk-on'"
                                    :off-icon="'svg-chk-off'"
                                    hide-details
                                    :id="'pop_qaVe_'+index"
                                    :name="'pop_qaVe_' + item.QA_QS_ID"
                                    v-model="item.QA_QS_VE_ID_3"
                                    :true-value="item.QA_QS_VE_ID"
                                    false-value=""
                                    @change="setSc('2')"
                                    >
                                  </v-checkbox>
                              </template>
                              <template v-else>
                                <v-icon small v-if="item.QA_QS_VE_ID_3 != null && item.QA_QS_VE_ID_3 != ''">mdi-check-circle</v-icon>
                              </template>
                            </template>
                          </template>
                        </td>
                        <template v-if="index == 0">
                          <td :rowspan="qaQsList.length">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.QA_TA_ST != '99'">
                              <v-textarea
                                no-resize
                                outlined
                                v-model="item.QA_DATA_RST_TCOM"
                              >
                              </v-textarea>
                            </template>
                            <template v-else>
                              {{item.QA_DATA_RST_TCOM}}
                            </template>
                          </td>
                        </template>
                      </tr>
                    </template>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";

export default {
  name: "MENU_M310301P01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    headerTitle: {
      type: String,
      default: "제목",
    },
    gridSelected:{},
    qaQsList:[],
    QA_MANAGE_YN : '',
  },
  data: () => ({
    footerHideTitle: "닫기",
  }),
  methods: {
    //만점 처리
    setPerfect(rst_index){
      let max_sc = 0;
      let max_index = '';
      let max_qa_qs_ve_id = '';
      let qa_qs_id = '';

      this.qaQsList.map(e =>{
        e['QA_QS_VE_ID_' + rst_index] = "";
        e['QA_DATA_RST_SUM_' + rst_index] = 0;
        e['QA_DATA_RST_COM_' + rst_index] = "";
      })
      this.qaQsList.map((e,index)=>{
        if(e.QA_QS_TY == '10'){
          if(qa_qs_id != e.QA_QS_ID && max_sc != 0 && max_sc != null){
            this.qaQsList[max_index]['QA_QS_VE_ID_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_ID;
            document.querySelector('input[id="pop_radio_rst'+ rst_index +'_' + max_qa_qs_ve_id +'"]').checked = true;
            this.qaQsList[max_index]['QA_DATA_RST_SUM_' + rst_index] = this.qaQsList[max_index].QA_QS_TY_SC;

            max_sc = e.QA_QS_VE_SC
            max_index = index;
            max_qa_qs_ve_id = e.QA_QS_VE_ID;
            qa_qs_id = e.QA_QS_ID;
          }else{
            if(max_sc < e.QA_QS_VE_SC){
              max_sc = e.QA_QS_VE_SC
              max_index = index;
              max_qa_qs_ve_id = e.QA_QS_VE_ID;
              qa_qs_id = e.QA_QS_ID;
            }
          }
        }else if(e.QA_QS_TY == '20'){
          if(max_index != ''){
            this.qaQsList[max_index]['QA_QS_VE_ID_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_ID;
            document.querySelector('input[id="pop_radio_rst'+ rst_index +'_' + max_qa_qs_ve_id +'"]').checked = true;
            this.qaQsList[max_index]['QA_DATA_RST_SUM_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_SC;
            max_sc = 0;
            max_index = '';
            max_qa_qs_ve_id = '';
            qa_qs_id = '';
          }
          e['QA_DATA_RST_SUM_' + rst_index] = e.QA_QS_VE_SC;
          e['QA_QS_VE_ID_' + rst_index] = e.QA_QS_VE_ID;
        }else if(e.QA_QS_TY == '30'){
          if(max_index != ''){
            this.qaQsList[max_index]['QA_QS_VE_ID_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_ID;
            document.querySelector('input[id="pop_radio_rst'+ rst_index +'_' + max_qa_qs_ve_id +'"]').checked = true;
            this.qaQsList[max_index]['QA_DATA_RST_SUM_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_SC;
            max_sc = 0;
            max_index = '';
            max_qa_qs_ve_id = '';
            qa_qs_id = '';
          }
          e['QA_QS_VE_ID_' + rst_index] = e.QA_QS_VE_ID;
          e['QA_DATA_RST_SUM_' + rst_index] = e.QA_QS_VE_SC;
        }
      });

      if(max_index != ''){
        this.qaQsList[max_index]['QA_QS_VE_ID_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_ID;
        document.querySelector('input[id="pop_radio_rst'+ rst_index +'_' + max_qa_qs_ve_id +'"]').checked = true;
        this.qaQsList[max_index]['QA_DATA_RST_SUM_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_SC;
        max_sc = 0;
        max_index = '';
        max_qa_qs_ve_id = '';
        qa_qs_id = '';
      }
    },
    setSc(rst_index){
      this.setVeId(rst_index);

      this.qaQsList.map(e =>{
        if(e.QA_QS_TY == '10' || e.QA_QS_TY == '30'){
          if(e['QA_QS_VE_ID_' + rst_index] != null && e['QA_QS_VE_ID_' + rst_index] != ''){
            e['QA_DATA_RST_SUM_' + rst_index] = e.QA_QS_VE_SC;
          }else{
            e['QA_DATA_RST_SUM_' + rst_index] = null;
            e['QA_DATA_RST_COM_' + rst_index] = "";
          }
        }
      });
    },
    setVeId(rst_index){
      this.qaQsList.map(e=>{
        if(e.QA_QS_TY == '10'){
          if(document.querySelector('input[id="pop_radio_rst'+ rst_index +'_' + e.QA_QS_VE_ID +'"]:checked') != null){
            e['QA_QS_VE_ID_'+ rst_index] = document.querySelector('input[id="pop_radio_rst'+ rst_index +'_' + e.QA_QS_VE_ID +'"]').value;
            //document.querySelector('input[id="radio_rst'+ rst_index +'_' + e.QA_QS_VE_ID +'"]').checked = true;
          }else{
            e['QA_QS_VE_ID_'+ rst_index] = null;
          }
        }else if(e.QA_QS_TY == '20'){
          e['QA_QS_VE_ID_'+ rst_index] = e.QA_QS_VE_ID;
        }
      });
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.gridSelected.QA_ST_DTTM;
      var newEndDate = this.gridSelected.QA_EN_DTTM;
      if(newStartDate == null){
        newStartDate = '';
      }

      if(newEndDate == null){
        newEndDate = '';
      }
      return newStartDate + " ~ " + newEndDate;
    },
    getQaSt(){
      let cn = this.gridSelected.QA_DATA_RST_CN
      let ret = "";
      if(cn == 0){
        ret = "1차"
      }else if(cn == null){
        ret = '';
      }else{
        ret = cn +"차완료"
      }
      return ret;
    },
    getCn1Sc(){
      let sc = 0;
      this.qaQsList.map(e => {
        if(e.QA_DATA_RST_SUM_1 != null && e.QA_DATA_RST_SUM_1 > 0){
          sc += parseInt(e.QA_DATA_RST_SUM_1);
        }
      })
      return sc;
    },
    getCn2Sc(){
      let sc = 0;
      this.qaQsList.map(e => {
        if(e.QA_DATA_RST_SUM_2 != null && e.QA_DATA_RST_SUM_2 > 0){
          sc += parseInt(e.QA_DATA_RST_SUM_2);
        }
      })
      return sc;
    },
    getCn3Sc(){
      let sc = 0;
      this.qaQsList.map(e => {
        if(e.QA_DATA_RST_SUM_3 != null && e.QA_DATA_RST_SUM_3 > 0){
          sc += parseInt(e.QA_DATA_RST_SUM_3);
        }
      })
      return sc;
    },

  },
};
</script>

<style></style>
